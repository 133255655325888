const ServiceData= [
    {
      id: 1,
      cName: 'fas fa-code',
      title:'Web Designing',
      desc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae laborum placeat quidem sed nesciunt, dolorem omnis odio, fuga animi aut tempore! Cumque officia laborum dolore! `,
      url:"/"
    },
    {
      id: 2,
      cName: 'fas fa-database',
      title:'Web Development',
      desc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae laborum placeat quidem sed nesciunt, dolorem omnis odio, fuga animi aut tempore! Cumque officia laborum dolore! `,
      url:"/"
    },
    {
        id: 3,
        cName: 'fas fa-paper-plane',
        title:'Seo',
        desc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae laborum placeat quidem sed nesciunt, dolorem omnis odio, fuga animi aut tempore! Cumque officia laborum dolore! `,
        url:"/"
        
      },
    {
      id: 4,
      cName: 'fas fa-bullhorn',
      title:'Digital Marketing',
      desc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae laborum placeat quidem sed nesciunt, dolorem omnis odio, fuga animi aut tempore! Cumque officia laborum dolore! `,
      url:"/"
    },
    {
        id: 5,
        cName: 'fas fa-pencil-alt',
        title:'Graphic Designing',
        desc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae laborum placeat quidem sed nesciunt, dolorem omnis odio, fuga animi aut tempore! Cumque officia laborum dolore! `,
        url:"/"
      },
      {
        id: 6,
        cName: 'fas fa-store-alt',
        title:'eCommerce',
        desc: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae laborum placeat quidem sed nesciunt, dolorem omnis odio, fuga animi aut tempore! Cumque officia laborum dolore! `,
        url:"/"
        
      }
     
  ];
  export default ServiceData;