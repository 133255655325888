import SliderOne from '../Media/slider1.jpg'
import SliderTwo from '../Media/slider2.jpg'
const sliderData= [
  {
    title: "INNOVATION",
    description: "We Create The Opportunities",
    urls: SliderOne,
  },
  {
    title: "SPECIALIZE",
    description: "Success Depends On Work",
    urls: SliderTwo,
  },
];
export default sliderData