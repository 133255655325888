const TeamData= [
    {
      id: 1,
      img: 'http://www.team.gsamdani.com/wp-content/uploads/2016/05/tm5.jpg',
      name:'Syeda Maham Fahim',
      title: 'Designer',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      email:"syedamahamfahim@gmail.com",
      url:'',
      
    },
    {
      id: 2,
      img: 'https://olizadevelopers.com/wp-content/uploads/2019/12/img-team-member-career-03.jpg',
      name:'Syeda Aimal Fahim',
      title: 'Developer',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      email:"syedamahamfahim@gmail.com",
      url:''
    },
    {
      id: 3,
      img: 'http://www.team.gsamdani.com/wp-content/uploads/2016/05/tm6.jpg',
      name:'Bakhtawar Fahim',
      title: 'CEO',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      email:"syedamahamfahim@gmail.com",
      url:''
    },
    {
      id: 4,
      img: 'https://cdn.powerpackelements.com/wp-content/uploads/2017/11/Team-memeber-01.png',
      name:'Ayesha Rashid',
      title: 'AI Expert',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      email:"syedamahamfahim@gmail.com",
      url:''
    }
  ];
  export default TeamData;
