import Blog1 from '../Media/blog-1.png'
import Blog2 from '../Media/blog-2.jpeg'
import Blog3 from '../Media/blog-3.jpg'
const BlogData= [
    {
      id: 1,
      heroImg: Blog1,
      title: 'buttermilk pancakes',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      date:'9-sep-2019',
      comments:"5",
      link:'/',
    },
    {
      id: 2,
      heroImg: Blog2,
      title: 'buttermilk pancakes',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      date:'9-sep-2019',
      comments:"5",
      link:'/',
    },
    {
      id: 3,
      heroImg: Blog3,
      title: 'buttermilk pancakes',
      desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
      date:'9-sep-2019',
      comments:"5",
      link:'/',
    },
    {
        id: 4,
        heroImg: 'http://aiclub.rcai.pk/wp-content/uploads/2019/06/futuristic-robot-artificial-intelligence-concept_31965-6501-1-370x250-1-300x203.jpg',
        title: 'buttermilk pancakes',
        desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
        date:'9-sep-2019',
        comments:"5",
        link:'/',
      },
      {
        id: 5,
        heroImg: 'http://aiclub.rcai.pk/wp-content/uploads/2019/06/futuristic-robot-artificial-intelligence-concept_31965-6501-1-370x250-1-300x203.jpg',
        title: 'buttermilk pancakes',
        desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
        date:'9-sep-2019',
        comments:"5",
        link:'https://facebook.com'
      },
      {
        id: 6,
        heroImg: 'http://aiclub.rcai.pk/wp-content/uploads/2019/06/futuristic-robot-artificial-intelligence-concept_31965-6501-1-370x250-1-300x203.jpg',
        title: 'buttermilk pancakes',
        desc: `I'm baby woke mlkshk wolf bitters live-edge blue bottle, hammock freegan copper mug whatever cold-pressed `,
        date:'9-sep-2019',
        comments:"5",
        link:'xnitia.com'
      }
  ];
  export default BlogData;