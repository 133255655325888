import ImageTwo from "./Media/2.jpg";
import ImageThree from "./Media/3.jpg";
import ImageFour from "./Media/4.jpg";
import ImageFive from "./Media/5.jpg";
import ImageSix from "./Media/6.jpg";
import ImageSeven from "./Media/7.jpg";
import ImageEight from "./Media/8.jpg";
import ImageNine from "./Media/9.jpg";
import ImageTen from "./Media/10.jpg";

const menu = [
  {
    id: 1,
    title: "Koumii Assisstance",
    category: "WEB DESIGNING ",
    url: "/",
    img: ImageTen,
    desc: `Koumii Assistance is a non-profit organization created in 2018`,
  },
  {
    id: 2,
    title: "i-Lead",
    category: "WEB DEVELOPING",
    url: "/",
    img: ImageTwo,
    desc: `I-LEAD's Associates is a consulting firm which work with educational institutions, businesses, individuals, and public and private non-profit organizations `,
  },
  {
    id: 3,
    title: "Chatura De Silva",
    category: "WEB DEVELOPING",
    url: "/",
    img: ImageThree,
    desc: `Chatura De Silva is a founder of Project Pursuit of Happiness (Project POH).`,
  },
  {
    id: 4,
    title: "Medsirus",
    category: "WEB DESIGNING ",
    url: "/",
    img: ImageFour,
    desc: `Medsirus is an eCommerce platform which provides  medical facilities`,
  },
  {
    id: 5,
    title: "Footy Lions",
    category: "WEB DEVELOPING",
    url: "/",
    img: ImageFive,
    desc: `Footy Lion is a football club that provide football training to 2-5 years childrens.`,
  },
  {
    id: 6,
    title: "HONORIS FOUNDATION",
    category: "ECOMMERCE SOLUTION",
    url: "/",
    img: ImageSix,
    desc: `HONORIS FOUNDATION is  a non-profitable US-based organization providing support to children all around Africa.`,
  },
  {
    id: 7,
    title: "I Like_Tax",
    category: "WEB DESIGNING ",
    url: "/",
    img: ImageSeven,
    desc: `i Like_Tax is a blogging website which deals with taxes. `,
  },
  {
    id: 8,
    title: "In2It",
    category: "WEB DEVELOPING",
    url: "/",
    img: ImageEight,
    desc: `on it tumblr kickstarter thundercats migas everyday carry squid palo santo leggings. Food truck truffaut  `,
  },
  {
    id: 9,
    title: "Koumii Assisstance",
    category: "ECOMMERCE SOLUTION",
    url: "/",
    img: ImageNine,
    desc: `Koumii Assistance is a non-profit organization created in 2018.`,
  },
];
export default menu;
